import PropTypes from 'prop-types';
import React from 'react';

import {
  SectionHowToGetFinancing,
  SectionConsultation,
  SectionLeasingAdvantages,
  SectionLeasingScheme,
} from '@product-site-frontend/shared';
import { graphql } from 'gatsby';

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import config from '../../config/website';
import logo from '../../static/images/logo.png';
import PageBox from '../components/PageBox';
// import SectionCalculator from '../components/SectionCalculator';
import SectionAboutLeasing from '../components/SectionAboutLeasing';
import SectionBusinessSegments from '../components/SectionBusinessSegments';
import SectionMain from '../components/SectionMain';
import SectionPartners from '../components/SectionPartners';
import SEO from '../components/SEO';

import '../assets/main.scss';

IndexPage.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.object,
    aboutLeasingContent: PropTypes.object,
    leasingAdvantagesContent: PropTypes.object,
    leasingSchemeContent: PropTypes.object,
    mainSectionContent: PropTypes.object,
    medicine: PropTypes.object,
    home: PropTypes.object,
  }),
};

export default function IndexPage({ data }) {
  const {
    aboutLeasingContent,
    leasingAdvantagesContent,
    leasingSchemeContent,
    medicine,
  } = data;

  return (
    <PageBox>
      <SEO
        microData={{
          '@context': 'https://www.schema.org',
          '@type': 'Product',
          logo: `${config.siteUrl}${logo}`,
          name: medicine.seo.title,
          description: medicine.main.description,
          offers: {
            '@type': 'Offer',
            'priceCurrency': 'RUB',
            'price': `до ${medicine.main.dealAmount} млн.`
          }
        }}
        pageData={medicine.seo}
      />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            text: 'Лизинг медицинского оборудования'
          }
        ]}
        sx={{ breadcrumbsLine: { borderBottom: '1px solid #ebebeb' } }}
      />
      <SectionMain content={medicine.main} />
      <SectionAboutLeasing
        content={{ ...aboutLeasingContent, ...medicine.aboutLeasing }}
      />
      <SectionBusinessSegments content={medicine.businessSegments} />
      <SectionLeasingScheme content={leasingSchemeContent} />
      {/* <SectionCalculator /> */}
      <SectionLeasingAdvantages content={leasingAdvantagesContent} />
      <SectionHowToGetFinancing content={medicine.financing} />
      <SectionPartners content={medicine.Partners} />
      <SectionConsultation />
    </PageBox>
  );
}

export const query = graphql`
  query MedicinePageQuery {
    medicine: strapiMedicine {
      seo {
        title
        description
        meta {
          content
          name
        }
      }
      main {
        title
        description
        term
        dealAmount
        advanceRate
        bgDesktop {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
        bgMobile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
      aboutLeasing {
        title
        description
      }
      businessSegments {
        title
        cards {
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
            }
          }
        }
      }
      financing {
        title
        steps {
          title
          description
        }
        bgDesktop {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500, placeholder: NONE)
            }
          }
        }
      }
      Partners {
        title
        cards {
          id
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
    }
    aboutLeasingContent: strapiAboutLeasing {
      title
      description
      cards {
        text
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
    }
    leasingSchemeContent: strapiLeasingScheme {
      title
      caption
      steps {
        title
        description
      }
    }
    leasingAdvantagesContent: strapiLeasingAdvantages {
      cards {
        title
        description
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
      title
    }
  }
`;
